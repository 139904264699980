import F36Tokens from "@contentful/forma-36-tokens";
import { ReactNode } from "react";
import Head from "next/head";
import { css, Global } from "@emotion/react";

type Props = {
	children?: ReactNode;
};

const globalStyles = css`
	body {
		font-family: ${F36Tokens.fontStackPrimary};
		margin: 0;
	}
	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}
`;

const Layout = ({ children }: Props) => (
	<div>
		<Global styles={globalStyles} />
		<Head>
			<title>Contentful extensions for evernest</title>
			<meta charSet="utf-8" />
			<meta name="viewport" content="initial-scale=1.0, width=device-width" />
		</Head>
		{children}
	</div>
);

export default Layout;
